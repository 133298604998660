define("discourse/plugins/discourse-wechat-article/discourse/initializers/loadcss", ["exports", "discourse/lib/load-script", "discourse/lib/plugin-api", "discourse/lib/ajax"], function (_exports, _loadScript, _pluginApi, _ajax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "wechat-load-css",
    initialize(container) {
      (0, _pluginApi.withPluginApi)("0.8.22", api => {
        api.decorateCookedElement($elem => {
          const controller = Discourse.__container__.lookup("controller:topic");
          const externalId = controller.get("model.external_id");
          if (externalId) {
            function loadCSS_wct(url) {
              return new Promise(function (resolve) {
                function ensureStyleWithId(id) {
                  let style = document.getElementById(id);
                  if (!style) {
                    style = document.createElement('style');
                    style.id = id;
                    document.head.appendChild(style);
                  }
                  return style;
                }

                // 更新style标签的内容
                function updateStyleContent(id, newContent) {
                  const style = ensureStyleWithId(id);
                  style.innerHTML = newContent;
                }
                const cb = function (data) {
                  ensureStyleWithId("wct-cr-css");
                  updateStyleContent("wct-cr-css", data);
                  resolve();
                };
                (0, _ajax.ajax)({
                  url: url,
                  dataType: "text"
                }).then(cb);
              });
            }
            loadCSS_wct("//css.xinfinite.net/wct-cr-css/" + externalId + ".css").then(() => {
              console.log("CSS loaded");
            });

            // loadScript("/plugins/discourse-wechat-article/javascripts/common.js").then(() => {
            //     console.log("JS loaded");
            // });
            (0, _loadScript.default)("/plugins/discourse-wechat-article/stylesheets/common-v9.css", {
              css: true
            }).then(() => {
              console.log("common CSS loaded");
            });
          }
        }, {
          onlyStream: true
        });
      });
    }
  };
});